<template>
    <v-container fluid>

        <v-data-table :headers="headers" :items="items" :items-per-page="10" item-key="id" sort-by="name"
            mobile-breakpoint="0" calculate-widths class="elevation-4" :loading="loading_status" loader-height="10"
            loading-text="Cargando ...">
            <template #top>
                <v-toolbar rounded flat>
                    <h3>Ingresar Compra</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-form v-model="searchvalid" ref="form" @submit="get_report">
                    <v-row class="pa-2">
                        <v-col cols="12" md="3">
                            <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required outlined
                                type="date" :rules="f_required" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required outlined
                                type="date" :rules="f_required" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="brand" :items="brands" item-value="codigo" item-text="valor"
                                label="Marca" placeholder="Marca" clearable hide-details="auto" outlined
                                :rules="f_required" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn x-large color="success" ref="submitbtn" type="submit">Buscar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
            <template v-slot:[`item.ordered_cost`]="{ item }">
                <span>{{ "$" + parseFloat(item.ordered_cost).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.ordered_val`]="{ item }">
                <span>{{ "$" + parseFloat(item.ordered_val).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.order_age`]="{ item }">
                <span>{{ parseFloat(item.order_age).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.ordered_qty`]="{ item }">
                <span>{{ parseFloat(item.ordered_qty).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.delivered_qty`]="{ item }">
                <span>{{ parseFloat(item.delivered_qty).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.difference_qty`]="{ item }">
                <span>{{ parseFloat(item.difference_qty).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.delivered_val`]="{ item }">
                <span>{{ "$" + parseFloat(item.delivered_val).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.difference_val`]="{ item }">
                <span>{{ "$" + parseFloat(item.difference_val).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="showDoc(item)">
                    mdi-magnify
                </v-icon>
            </template>


            <!-- <template slot="body.append">
                <tr>
                    <th class="title">Total</th>
                    <th class="title"></th>
                    <th class="text-center ">{{ "$ " + sumTable('items', 'descuento') }}</th>
                    <th class="text-right ">{{ "$ " + sumTable('items', 'valor') }}</th>
                    <th class="text-right ">{{ sumTable('items', 'quantity') }}</th>
                </tr>
            </template> -->
        </v-data-table>

        <v-dialog v-model="order_dialog" scrollable max-width="800px">

            <v-card>

                <v-card-title class="pa-0">
                    <v-toolbar>
                        Productos a ingresar
                        <v-spacer></v-spacer>
                        <v-icon @click="order_dialog = !order_dialog">
                            mdi-close
                        </v-icon>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-data-table v-model="selected" :headers="header_order" :items="orderItems" item-key="product_code"
                        loading-text="Cargando ..." show-select disable-pagination calculate-widths hide-default-footer>
                        <template v-slot:[`item.quantity`]="{ item }">
                            <!-- <span>{{ parseFloat(item.quantity).toLocaleString(2) }}</span> -->
                            <v-edit-dialog :return-value.sync="item['quantity']" @save="save" save-text="Guardar"
                                @cancel="cancel" cancel-text="Cancelar" @open="open" @close="close" large> {{
                                        item['quantity']
                                }}
                                <template v-slot:input>
                                    <v-text-field label="Cantidad" v-model="item['quantity']" single-line outlined hide-details class="pt-4" dense />
                                   
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.order_cost`]="{ item }">
                            <span>{{ "$" + parseFloat(item.order_cost).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            <span
                                v-if="!((parseInt(item.price) - parseInt(item.price_inv)) !== 0 && item.price_inv != 0)">
                                {{ "$" + parseFloat(item.price).toLocaleString(2) }}
                            </span>
                            <span v-else>
                                {{ "$" + parseFloat(item.price).toLocaleString(2) }}
                                <v-icon class="mr-2" color="red">
                                    mdi-close
                                </v-icon>
                            </span>


                        </template>
                        <template slot="body.append">
                            <tr>
                                <th class="title">Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="text-right ">{{ sumTable('orderItems', 'quantity') }}</th>
                                <th class="text-right ">{{ "$" + sumTable('orderItems', 'order_cost') }}</th>
                                <th class="text-right ">{{ "$" + sumTable('orderItems', 'price') }}</th>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions dark color="secondary">
                    <v-spacer></v-spacer>
                    <v-form v-model="validIn" ref="formIn" @submit="StockIn">
                        <v-row>
                            <v-col cols="3" md="3">
                                <v-text-field v-model="SelectedCount" align="right" label="Seleccionados"
                                    hide-details="auto" outlined readonly />
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-select v-model="typeIn" :items="opin" item-value="codigo" item-text="valor"
                                    label="Tipo Ingreso" placeholder="Marca" clearable hide-details="auto" outlined
                                    :rules="f_required" />
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-text-field v-model="docRefIn" label="Referencia" hide-details="auto" required
                                    outlined :rules="f_required" />
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-btn x-large color="success" block type="submit" :disabled="btnIN"
                                    v-if="this.selected.length > 0 && !this.error_form">
                                    Ingresar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-actions>
            </v-card>
            <v-snackbar v-model="errorOrder" :timeout="timeout" absolute centered dark color="red" elevation="24">
                Existen productos con diferente precio en el inventario.
            </v-snackbar>
        </v-dialog>


    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";

import createDoc from "../utils/create_doc.js";
export default {
    components: {},
    data() {
        return {
            loading_status: false,
            headers: [
                {
                    text: "Fecha",
                    align: "start",
                    sortable: true,
                    value: "order_date",
                    dataType: "text",
                },
                {
                    text: "Orden",
                    align: "start",
                    sortable: true,
                    value: "order_id",
                    dataType: "text",
                },
                {
                    text: "Costo",
                    align: "center",
                    sortable: true,
                    value: "ordered_cost",
                    dataType: "number",
                },
                {
                    text: "Valor",
                    align: "end",
                    sortable: true,
                    value: "ordered_val",
                    dataType: "number",
                },
                {
                    text: "Días",
                    align: "end",
                    sortable: true,
                    value: "order_age",
                    dataType: "number",
                },
                {
                    text: "Pedidos",
                    align: "end",
                    sortable: true,
                    value: "ordered_qty",
                    dataType: "number",
                },
                {
                    text: "Entregados",
                    align: "end",
                    sortable: true,
                    value: "delivered_qty",
                    dataType: "number",
                },
                {
                    text: "Diferencia",
                    align: "end",
                    sortable: true,
                    value: "difference_qty",
                    dataType: "number",
                },
                {
                    text: "Entregados",
                    align: "end",
                    sortable: true,
                    value: "delivered_val",
                    dataType: "number",
                },
                {
                    text: "Diferencia",
                    align: "end",
                    sortable: true,
                    value: "difference_val",
                    dataType: "number",
                },
                { text: "", value: "actions", align: "end", sortable: false },
            ],
            header_order: [
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Referencia",
                    align: "start",
                    sortable: true,
                    value: "refe",
                    dataType: "text",
                },
                {
                    text: "Color",
                    align: "start",
                    sortable: true,
                    value: "colorName",
                    dataType: "text",
                },
                {
                    text: "Talla",
                    align: "start",
                    sortable: true,
                    value: "size",
                    dataType: "text",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    value: "quantity",
                    dataType: "number",
                },
                {
                    text: "Costo",
                    align: "end",
                    sortable: true,
                    value: "order_cost",
                    dataType: "number",
                },

                {
                    text: "Precio",
                    align: "end",
                    sortable: true,
                    value: "price",
                    dataType: "number",
                },
            ],
            items: [],
            dialog: false,
            item: createDoc(),
            dateFrom: getdays_ago(-30),
            dateTo: getToday(),
            chartData: null,
            chartOptions: {
                curveType: "function",
                legend: { position: "right" },
                interpolateNulls: true,
                crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
                title: "",
                isStacked: false,
                hAxis: {
                    title: "",
                    slantedText: true,
                    slantedTextAngle: 15,
                },
                colors: ['#1b9e77', '#d95f02', '#7570b3'],
                height: 400,
            },
            brand: null,
            brands: [],
            f_required: [(v) => !!v || "Requerido"],
            searchvalid: false,
            docId: null,
            docDate: null,
            orderItems: [],
            order_dialog: false,
            selected: [],
            opin: [],
            typeIn: null,
            docRefIn: null,
            validIn: false,
            SelectedCount: 0,
            error_form: false,
            errorOrder: false,
            timeout: 5000,
            btnIN: false,
        };
    },
    mounted() {
        this.brands = window.settings["MARCA"].sort(this.GetSortOrder("valor"));
        this.opin = window.settings["OP-IN"].sort(this.GetSortOrder("valor"));
        console.log(this.opin);
    },
    methods: {
        save() { },
        cancel() { },
        open() { },
        close() { },
        timeBtn() {
            this.btnIN = true;
            setTimeout(() => {
                this.btnIn = false;
            }, 1000);
        },
        showDoc(item) {
            this.selected = [];
            this.docId = item.order_id;
            this.docDate = item.order_date;
            this.$refs.submitbtn.$el.click();
            this.order_dialog = true;

        },
        sumTable(table, key) {
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },
        get_report(e) {
            e.preventDefault();
            this.$refs.form.validate();
            if (this.searchvalid) {
                this.loading_status = true;
                var qry = {
                    store: window.store.store_id,
                    from: this.dateFrom,
                    to: this.dateTo,
                    order_id: this.docId,
                    brand: this.brand,
                };
                this.loading_status = true;
                webserver("get_orders", qry, (data) => {
                    if (this.docId) {
                        this.error_form = false;
                        data.forEach((item) => {
                            if ((parseInt(item.price) - parseInt(item.price_inv)) !== 0 && item.price_inv != 0) {
                                console.log('ERROR', item)
                                this.error_form = true;
                            }
                        });
                        if (this.error_form) this.errorOrder = true;
                        this.orderItems = data;
                    } else {
                        this.items = data;
                    }
                    this.docId = null;
                    this.loading_status = false;
                });
            }
        },
        GetSortOrder(prop) {
            return function (a, b) {
                if (a[prop] > b[prop]) {
                    return 1;
                } else if (a[prop] < b[prop]) {
                    return -1;
                }
                return 0;
            };
        },
        StockIn(e) {
            e.preventDefault();
            this.$refs.formIn.validate();
            if (this.validIn) {
                this.btnIN = true;
                var qry = {};
                qry.store = window.store.store_id,
                    qry.issueDate = getToday();
                qry.user_id = window.profile.user_email,
                    qry.orderRef = this.typeIn + '-' + this.docRefIn;
                qry.data = this.selected;
                console.log(qry);
                this.loading_status = true;
                webserver("order_in", qry, (data) => {
                    console.log(data);
                    this.typeIn = null;
                    this.docRefIn = null;
                    this.loading_status = false;
                    this.order_dialog = false;
                    this.$refs.submitbtn.$el.click();
                    this.btnIN = false;
                });
            }


        }
    },
    watch: {
        selected: function () {
            this.SelectedCount = 0;
            this.selected.forEach((itm) => {
                this.SelectedCount += parseFloat(itm.quantity);
            });
        },
    },
};
</script>

<style>
.v-dialog {
    overflow-y: hidden !important;
}
</style>
